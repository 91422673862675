import Callout from "../../../../src/components/Callout";
import Sidenote from "../../../../src/components/Sidenote";
import StockWordpress from "../../../../src/images/blog/on-keeping-a-changelog/stock-wordpress.png";
import ThemedWordpress from "../../../../src/images/blog/on-keeping-a-changelog/themed-wordpress.png";
import Ember1 from "../../../../src/images/blog/on-keeping-a-changelog/ember1.png";
import Ember2 from "../../../../src/images/blog/on-keeping-a-changelog/ember2.png";
import ReleaseTrain from "../../../../src/images/blog/train.jpg";
import * as React from 'react';
export default {
  Callout,
  Sidenote,
  StockWordpress,
  ThemedWordpress,
  Ember1,
  Ember2,
  ReleaseTrain,
  React
};