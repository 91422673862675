import Callout from "../../../../src/components/Callout";
import ComponentImage from "../../../../src/images/blog/writing-a-simple-program-in-elm/component.png";
import Coffee from "../../../../src/images/blog/writing-a-simple-program-in-elm/coffee.png";
import Debugger from "../../../../src/images/blog/writing-a-simple-program-in-elm/debugger.png";
import EmptyScreen from "../../../../src/images/blog/writing-a-simple-program-in-elm/empty-screen.png";
import Meetup from "../../../../src/images/blog/writing-a-simple-program-in-elm/meetup.png";
import OpenSource from "../../../../src/images/blog/writing-a-simple-program-in-elm/open-source.png";
import Talk from "../../../../src/images/blog/writing-a-simple-program-in-elm/talk.png";
import * as React from 'react';
export default {
  Callout,
  ComponentImage,
  Coffee,
  Debugger,
  EmptyScreen,
  Meetup,
  OpenSource,
  Talk,
  React
};