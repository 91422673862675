import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../styles/globalStyles';

const CalloutBackground = styled.aside`
  position: relative;
  background: ${Colors.grayLighter};
  padding: 30px;
  border-radius: 8px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  h3,
  p,
  ul,
  li {
    &:first-of-type {
      margin-top: 0;
      &:first-letter {
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const IconContainer = styled.span`
  position: absolute;
  font-size: 2rem;
  top: -25px;
  left: -25px;
  width: 60px;
  height: 60px;
  text-align: center;
`;

type CalloutType =
  | 'info'
  | 'error'
  | 'success'
  | 'warning'
  | 'question'
  | 'intro'
  | 'date';

const getEmojiForCallout = (type: CalloutType) => {
  let emoji;

  switch (type) {
    case 'info':
      emoji = '💡';
      break;
    case 'error':
      emoji = '❌';
      break;
    case 'success':
      emoji = '✅';
      break;
    case 'warning':
      emoji = '⚠️';
      break;
    case 'question':
      emoji = '🤔';
      break;
    case 'intro':
      emoji = '📢';
      break;
    case 'date':
      emoji = '🗓';
      break;
    default:
      emoji = '💡';
      break;
  }
  return (
    <IconContainer role="img" aria-label={`${type} emoji`}>
      {emoji}
    </IconContainer>
  );
};

interface CalloutProps {
  type: CalloutType;
}

const Callout = ({
  children,
  type = 'info',
}: PropsWithChildren<CalloutProps>) => {
  return (
    <CalloutBackground>
      {getEmojiForCallout(type)}
      {children}
    </CalloutBackground>
  );
};

export default Callout;
