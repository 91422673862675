import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../styles/globalStyles';

interface SidenoteProps {
  title: string;
  children: React.ReactElement;
}

const SidenoteAside = styled.aside`
  border: 1px solid ${Colors.grayLight};
  border-radius: 1rem;
  padding: 1.5rem;
  color: ${Colors.grayDark};
  font-size: 14px;
  margin-bottom: 1rem;

  strong {
    color: black;
  }

  a {
    colors: ${Colors.red};
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Sidenote = ({ title = 'Sidenote', children }: SidenoteProps) => {
  return (
    <SidenoteAside>
      <strong>{title ? `${title}: ` : ''}</strong>
      {children}
    </SidenoteAside>
  );
};

export default Sidenote;
