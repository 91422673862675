import Callout from "../../../../src/components/Callout";
import Img0 from "../../../../src/images/blog/building-a-culture-of-documentation/0.png";
import Img1 from "../../../../src/images/blog/building-a-culture-of-documentation/1.png";
import Img2 from "../../../../src/images/blog/building-a-culture-of-documentation/2.png";
import Img3 from "../../../../src/images/blog/building-a-culture-of-documentation/3.png";
import Img4 from "../../../../src/images/blog/building-a-culture-of-documentation/4.png";
import Img5 from "../../../../src/images/blog/building-a-culture-of-documentation/5.png";
import Img6 from "../../../../src/images/blog/building-a-culture-of-documentation/6.png";
import Img7 from "../../../../src/images/blog/building-a-culture-of-documentation/7.png";
import * as React from 'react';
export default {
  Callout,
  Img0,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  React
};