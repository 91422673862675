import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import Footnotes from './src/components/Footnotes';
import BlogStyles from './src/styles/blogStyles';

/* eslint-disable */
const components = {
  a: ({ children, href }) => {
    return (
      <a href={href} target={href.startsWith('http') ? '_blank' : '_self'}>
        {children}
      </a>
    );
    return props;
  },
  pre: (props) => {
    const className = props.children.props.className || '';
    const matches = className.match(/language-(?<lang>.*)/);
    return (
      <>
        <BlogStyles />
        <Highlight
          {...defaultProps}
          code={props.children.props.children}
          language={
            matches && matches.groups && matches.groups.lang
              ? matches.groups.lang
              : ''
          }
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
              {/* Had to add slice here to remove an unnecesseary empty line at the end */}
              {tokens.slice(0, tokens.length - 1).map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </>
    );
  },
  wrapper: ({ children }) => {
    const updatedChildren = children.map((child, index) => {
      if (child.props.className === 'footnotes') {
        // Since we only have one element that will ever match this
        // the key doesn't matter, but react will yell without a key.
        return <Footnotes key={index} {...child.props} />;
      }
      return child;
    });
    return <>{updatedChildren}</>;
  },
};
export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
