import React from 'react';
import { Global, css } from '@emotion/react';

const BlogStyles = () => {
  <Global
    styles={css`
      code[class*='language-'],
      pre[class*='language-'] {
        color: #d6deeb;
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
      }
      code[class*='language-'] ::-moz-selection,
      code[class*='language-']::-moz-selection,
      pre[class*='language-'] ::-moz-selection,
      pre[class*='language-']::-moz-selection {
        text-shadow: none;
        background: rgba(29, 59, 83, 0.99);
      }
      code[class*='language-'] ::selection,
      code[class*='language-']::selection,
      pre[class*='language-'] ::selection,
      pre[class*='language-']::selection {
        text-shadow: none;
        background: rgba(29, 59, 83, 0.99);
      }
      @media print {
        code[class*='language-'],
        pre[class*='language-'] {
          text-shadow: none;
        }
      }
      pre[class*='language-'] {
        padding: 1em;
        margin: 0.5em 0;
        overflow: auto;
      }
      :not(pre) > code[class*='language-'],
      pre[class*='language-'] {
        color: #fff;
        background: #011627;
      }
      :not(pre) > code[class*='language-'] {
        padding: 0.1em;
        border-radius: 0.3em;
        white-space: normal;
      }
      .token.cdata,
      .token.comment,
      .token.prolog {
        color: #637777;
        font-style: italic;
      }
      .token.punctuation {
        color: #c792ea;
      }
      .namespace {
        color: #b2ccd6;
      }
      .token.deleted {
        color: rgba(239, 83, 80, 0.56);
        font-style: italic;
      }
      .token.property,
      .token.symbol {
        color: #80cbc4;
      }
      .token.keyword,
      .token.operator,
      .token.tag {
        color: #7fdbca;
      }
      .token.boolean {
        color: #ff5874;
      }
      .token.number {
        color: #f78c6c;
      }
      .token.builtin,
      .token.char,
      .token.constant,
      .token.function {
        color: #82aaff;
      }
      .token.doctype,
      .token.selector {
        color: #c792ea;
        font-style: italic;
      }
      .token.attr-name,
      .token.inserted {
        color: #addb67;
        font-style: italic;
      }
      .language-css .token.string,
      .style .token.string,
      .token.entity,
      .token.string,
      .token.url {
        color: #addb67;
      }
      .token.atrule,
      .token.attr-value,
      .token.class-name {
        color: #ffcb8b;
      }
      .token.important,
      .token.regex,
      .token.variable {
        color: #d6deeb;
      }
      .token.bold,
      .token.important {
        font-weight: 700;
      }
      .token.italic {
        font-style: italic;
      }
    `}
  />;
};

export default BlogStyles;
