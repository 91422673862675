module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vinay Raghu - Software Engineer","short_name":"Vinay Raghu","start_url":"/","background_color":"#000000","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6bc0b33650f90c1e4c4aa47a27c09b12"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,700","Titillium Web:300,400,700","IBM Plex Mono:400"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[{}],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-highlight-code","id":"9ac5e00d-04d3-528d-bcbb-7d384a724d36","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers","id":"84411983-3572-54e6-8782-dedc5abaf1a7","name":"gatsby-remark-autolink-headers","version":"5.13.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
