import { TextCenter } from "../../../../src/styles/globalStyles";
import VennDiagram from "../../../../src/images/blog/talented-and-kind/venn-diagram.png";
import Callout from "../../../../src/components/Callout";
import Zoom from 'react-medium-image-zoom';
import * as React from 'react';
export default {
  TextCenter,
  VennDiagram,
  Callout,
  Zoom,
  React
};